@import "../../styles/variables";

.container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  .select {
    padding: 0px;
    margin-right: 10px;
    min-width: 40%;
  }
  .input {
    min-width: 250px;
  }
  .delete {
    @include btn;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-size: 30px;
    color: rgba($dark, 0.5);
    &:hover {
      color: $danger;
    }
  }
}
