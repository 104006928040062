@import "../../../styles/variables";

.container {
  height: 100%;
  width: 100%;
  header {
    padding: 20px 70px;
    display: flex;
    align-items: center;
    h2 {
      margin: 0px 0px 0px 0px;
      @include bold;
      text-transform: uppercase;
    }
    .back {
      @include back-btn;
    }
  }
  .form {
    padding: 10px 70px;
    overflow: auto;
    height: calc(100% - 67px);
    max-width: 100%;
    flex-wrap: wrap;
    @media only screen and (min-width: $md-screen) {
      max-width: 90%;
    }
    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .col {
        width: calc(50% - 10px);
        .file {
          @include file;
          .img {
            height: auto;
          }
        }
        .container-field {
          width: 100%;
          margin-bottom: 10px;
        }
        .btn-container {
          margin-top: 20px;
          .btn {
            @include btn;
            &.danger {
              margin-left: 20px;
            }
          }
        }
      }
    }
    .message-error {
      color: $danger;
    }
  }
}