
@import '../../styles/variables.scss';

@mixin inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    margin-right: 10px;
  }
}

@mixin label {
  font-size: 14px;
  color: #84899A;
}

@mixin input {
  @include transition;
  display: block;
  padding: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 0px;
  width: 100%;
  min-width: 250px;
  box-sizing: border-box;
  @include text;
  &:focus{
    border: solid 1px $primary-color;
  }
  &::placeholder {
    @include text;
    color : #a3a3a3;
  }
    // &:-webkit-autofill,
    // &:-webkit-autofill:hover, 
    // &:-webkit-autofill:focus, 
    // &:-webkit-autofill:active  {
    //   box-shadow: 0 0 0 30px $dark-grey inset !important;
    //   color: $white !important;
    //   font-size: $regular;
    //   -webkit-text-fill-color: $white !important;
    // }
}

@mixin primary {
  .input {
    @include input;
    border-radius: $radius-md;
    min-height: 50px;
    border: 1px solid rgba($dark, 0.2);
    background-color: $bg-color;
    @include label;
    color: $black;
  }
  label {
    @include medium;
    color: rgba($dark, 0.5);
  }
}

@mixin login {
  .input {
    @include input;
    border-radius: $radius;
    min-height: 50px;
    background-color: rgba($white , 0.2);
    border: solid 1px #dcdcdc;
    color: $white;
  }
  label {
    color: $white;
  }
};