@import '../../styles/_variables.scss';

.container {
  position: fixed;
  top: 0px;
  background-color: rgba($dark, 0.6);
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 301;
  display: none;
  &.active {
    display: block;
  }
  .close {
    @include btn;
    position: absolute;
    right: 0px;
    top: 5px;
    font-size: 30px;
    color: $white;
    z-index: 302;
    &:hover {
      transform: scale(1.1);
    }
  }
  .arrow {
    @include btn;
    position: fixed;
    z-index: 303;
    top: 48%;
    color: $white;
    font-size: 50px;
    &.right {
      right: 0px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  .slider {
    @include transition;
    height: 100%;
    display: flex;
    position: relative;
    .card {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
        .img {
          width: 60%;
          max-width: 60%;
          min-height: 60%;
          max-height: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          button {
            @include btn;
            transform: translateY(-24px);
          }
          img {
            width: 100%;
            max-height: 80vh;
            object-fit: contain;
          }
        }
      
    }
  }
}