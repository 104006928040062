@import "../../styles/variables";

@mixin inputList {
  width: 100%;
  @include transition;
  background-color: rgba($dark, 0.05);
  border-radius: $radius-md;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    max-width: 100px;
    @include transition;
  }
  &:hover {
    cursor: pointer;
    img {
      transform: scale(1.1) rotate(10deg);
    }
  }
}

.container {
  display: flex;
  input {
    display: none;
  }
  p {
    color: $danger;
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
  }
  span {
    @include medium;
    color: rgba($dark, 0.5)
  }
  .btn {
    display: block;
    @include btn;
  }
  .list {
    @include inputList;
    height: 180px;
    span {
      @include bold;
    }
  }
  .large {
    @include inputList;
    height: 300px;
    span {
      @include bold;
    }
  }
  .drag {
    @include inputList;
    padding: 120px 0px;
  }
}

