@import "../../styles/variables";

.container {
  border-bottom: 1px solid rgba(28, 55, 107, 0.3);
  margin-bottom: 20px;
  padding-bottom: 20px;
  &.active {
    padding-bottom: 25px;
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      &.label {
        margin: 0px !important;
      }
    }
    button {
      @include btn;
      .minus {
        @include transition;
        height: 3px;
        width: 17px;
        background-color: $primary-color;
        transform: translateY(2px) rotate(90deg);
        opacity: 0;
      }
      .plus {
        @include transition;
        height: 3px;
        width: 17px;
        background-color: $primary-color;
        transform: translateY(-2px);
      }
      &.active {
        .plus {
          transform: translateY(-2px) rotate(90deg);
        }
        .minus {
          transform: translateY(1px) rotate(0deg);
          opacity: 1;
        }
      }
    }
  }
  .files {
    width: 100%;
    .list {
      width: 100%;
      margin: 20px 0px;
      display: grid;
      grid-gap: 30px;
      grid-auto-rows: minmax(180px, auto);
      grid-template-columns: repeat(2, 1fr);
      .file {
        @include file;
      }
    }
  }
}