@import '../../styles/_variables.scss';

.container {
  @include transition;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #DDF5F9;
  border: 1px dashed #33B0C5;
  border-radius: 9px;
  text-decoration: none;
  color: $dark;
  margin: 0px 0px 20px 0px;
  p {
    text-align: center;
    font-size: 14px;
    @include bold;
    color: $dark;
  }
  button {
    @include btn;
    &:hover {
      transform: scale(1.05);
    }
  }
}
