@import '../../styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  background-color: $dark;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-image: url('../../assets/images/bg.png');
  .logo {
    display: flex;
    justify-content: center;
    img {
      width: 280px;
    }
  }
  h4 {
    text-align: center;
  }
}
