@import "../../../styles/variables";

.container {
  height: 100%;
  width: 100%;
  header {
    padding: 20px 70px;
    h2 {
      margin: 0px 40px 0px 0px;
      @include bold;
      text-transform: uppercase;
    }
    .btn {
      @include btn;
      position: absolute;
      top: 90px;
      right: 70px;
      text-decoration: none;
    }
  }
  .list {
    padding: 10px 70px;
    overflow: auto;
    height: calc(100% - 67px);
  }
  .loader {
  }
}