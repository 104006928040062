@import '../../styles/variables.scss';

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
  }
  .btn {
    @include btn;
    &.danger {
      border: 1px solid $danger;
    }
  }
}