@import '../../../styles/variables.scss';

.rdw-option-wrapper {
  background-color: rgba($white, 0.1);
  border: 1px solid rgba($white, 0);
  margin: 0px 10px 0px 0px;
  width: 25px;
  height: 25px;
  &:hover {
    box-shadow: $shadow;
    background-color: rgba($white, 0.50);  
  }
}

.rdw-option-active {
  box-shadow: $shadow;
  background-color: rgba($primary-color, 0.8); 
}

.rdw-link-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
  .rdw-dropdown-wrapper {
    height: 25px;
    cursor: pointer;
    background-color: rgba($white, 0.1);
    border: 1px solid rgba($white, 0);
    border-radius: 2px;
    margin: 0 3px;
    text-transform: capitalize;
    &:hover {
      box-shadow: $shadow;
    }
  }
}

.rdw-dropdown-optionwrapper {
  z-index: 300;
  position: relative;
  width: 100%;
  background-color: $white;
  border: 1px solid $white;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: $shadow;
  &:hover {
    background-color: $white;
    border: 1px solid $white;
    box-shadow: $shadow;
  }
}

.rdw-link-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  border: 1px solid transparent;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: $white;
  box-shadow: $shadow;
  label {
    color: rgba($white, 0.50);
  }
  input {
    border: 1px solid transparent;
    background-color: rgba($white, 0.1);
    color: rgba($white , 0.8);
  }
  .rdw-link-modal-target-option {
    display: none;
  }
  .rdw-link-modal-btn {
    background-color: rgba($primary-color, 0.8);
    border: 1px solid transparent;
    text-transform: uppercase;
    &:hover {
      box-shadow: $shadow;
    }
  }
}