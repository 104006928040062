@import '../../styles/_variables.scss';

.container {
  @include btn;
  @include transition;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  color: $dark;
  cursor: pointer;
  &:hover {
    .img {
      transform: scale(1.015);
    }
  }
  .img {
    @include transition;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .infos {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 10px;
    p {
      @include medium;
      color: $dark;
      font-size: 13px;
      background: #F9F9FA;
      border: 2px solid #ECECF0;
      border-radius: 9px;
      padding: 4px 8px;
      margin-right: 10px;
      text-transform: uppercase;
      svg {
        font-size: 20px;
      }
      @media only screen and (min-width: $md-screen) {
        padding: 8px 15px;
        font-size: 14px;
      }
    }
    .icon {
      @include medium;
      color: $dark;
      font-size: 14px;
      background: #F9F9FA;
      border: 2px solid #ECECF0;
      border-radius: 9px;
      height: 37px;
      width: 37px;
      display: none;
      margin-right: 10px;
      @media only screen and (min-width: $md-screen) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      svg {
        font-size: 20px;
      }
    }
  }
}
