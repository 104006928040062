@import '../../styles/_variables.scss';

.container {
  width: 100vw;
  padding: 20px 40px;
  height: 100%;
  overflow: auto;
  display: flex;
  @media only screen and (min-width: $sm-screen) {
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 70px;
  }
  .form {
    width: 100%;
    @media only screen and (min-width: $sm-screen) {
      justify-content: space-between;
      width: calc(50% - 20px);
    }
    label {
      @include medium;
      color: rgba($dark, 0.5); 
    }
    .container-field {
      margin-bottom: 10px;
    }
    .types {
      display: flex;
      justify-content: flex-start;
      border: 1px solid rgba($dark, 0.2);
      margin-bottom: 20px;
      border-radius: $radius-md;
      width: 250px;
      height: 50px;
      button {
        width: 50%;
        color: rgba($dark, 0.4);
        @include btn;
        font-family: "Montserrat-SemiBold";
        &.active {
          background-color: $dark;
          border-radius: $radius-md;
          color: $white;
        }
      }
    }
    .public {
      display: flex;
      margin-bottom: 20px;
      button {
        margin-left: 10px;
        @include btn;
        display: flex;
        align-items: center;
        @include medium;
        color: rgba($dark, 0.5); 
        .check {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          border-radius: 5px;
          border: 1px solid rgba($dark, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: transparent;
          }
          &.active {
            background-color: $primary-color;
            svg {
              color: $white;
            }
          }
        }
      }
    }
    .formats {
      margin-top: 30px;
      .label {
        display: flex;
        p {
          margin: 6px 10px 6px 0px;
        }
      }
      .add {
        margin: 20px 10px 20px 0px;
        @include btn;
      }
      .format-item {
        display: flex;
        p {
          margin-bottom: 0px;
          font-size: 14px;
          color: $dark;
          @include medium;
        }
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
    .content {
      margin: 10px 0px;
    }
    .info {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 20px;
      p {
        margin: 0px;
        font-size: 14px;
        color: $dark;
        @include medium;
      }
      &.bg {
        width: calc(100% + 40px);
        transform: translateX(-20px);
        padding: 20px;
        background-color: rgba($dark, 0.05);
        border-radius: $radius-md;
      }
    }
    .files {
      margin-top: 10px;
      .list {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        &.recap {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
        a {
          text-decoration: none;
          @include medium;
          margin: 0px;
          text-decoration: none;
          color: $dark;
          margin: 0px 5px 5px 0px;
          &:hover {
            background-color: rgba($primary-color, 0.5);
          }
        }
        .file {
          background-color: rgba($dark, 0.1);
          margin-top: 10px;
          padding: 8px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: $radius-md;
          a {
            @include medium;
            margin: 0px;
            text-decoration: none;
            color: $dark;
            &:hover {
              text-decoration: underline;
            }
          }
          .delete {
            @include btn;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 0px;
            font-size: 30px;
            color: rgba($dark, 0.5);
            &:hover {
              color: $danger;
            }
          }
        }
      }
    }
    .btn-container {
      margin-top: 30px;
      .btn {
        @include btn;
        text-decoration: none;
        margin-right: 15px;
      }
    }
  }
  .help {
    width: 100%;
    margin-top: 10px;
    display: none;
    color: $primary-color;
    border-left: 1px solid rgba($dark, 0.2);
    padding: 0px 60px;
    @media only screen and (min-width: $sm-screen) {
      width: calc(50% - 20px);
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .title {
      width: 100%;
      display: flex;
      align-items: center;
      h2 {
        @include bold;
        font-size: 16px;
        margin: 0px 10px;
      }
    }
    ul {
      width: 100%;
      padding: 0px 20px;
      li {
        margin-bottom: 10px,;
      }
    }
  }
}