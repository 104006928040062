@import "../../styles/variables";

.container {  
  @include page-container;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  main {
    width: 100%;
    height: calc(100vh - 110px);
  }
}