@import '../../styles/_variables.scss';

.container {
  max-width: 450px;
  .form {
    margin-bottom: $margin-sm;
    .container-field{
      margin-bottom: 10px;
    }
    .message-error{
      color : $danger;
      font-size: 12px;
    }
    .message-success {
      color : $success;
      font-size: 14px;
    }
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    button {
      @include btn;
    }
  }
}
