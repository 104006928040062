@import '../../styles/_variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .logo {
    width: 250px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    img {
      width: 160px;
    }
  }
  .background {
    background-color: $dark;
    height: 115px;
    width: calc(100% - 250px);
    border-bottom-left-radius: 40px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .links {
      margin-left: 10px;
      display: flex;
      a {
        @include transition;
        @include medium;
        text-decoration: none;
        display: flex;
        align-items: center;
        margin: 0px 40px 0px 0px;
        opacity: 0.4;
        color: $white;
        @media only screen and (min-width: 1250px) {
          margin: 0px 50px 0px 0px;
        }
        &.active {
          opacity: 1;
        }
        &:hover {
          opacity: 1;
        }
        img {
          font-size: 22px;
          margin-right: 10px;
          display: none;
          @media only screen and (min-width: 1150px) {
            display: block;
          }
        }
      }
    }
    .profile {
      display: flex;
      align-items: center;
      color: $white;
      p {
        @include medium;
        color: $white;
        margin: 0px;
        display: none;
        @media only screen and (min-width: 1250px) {
          display: block;
        }
      }
      .logout {
        @include btn;
        color: $white;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        border-radius: 40px;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          background-color: rgba($black, 0.5);
        }
      } 
    }
  }
}
