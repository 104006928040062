@import '../form-mixin.scss';
.date-picker{

  label{
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.container-input{
      margin-top: 10px;
    }
  }

  .container-input{
    position: relative;
    width: 100%;
    max-width: 250px;
    .container-icon{
      display: block;
      position: absolute;
      top: 2px;
      right:0;
      height: 45px;
      width: 45px;
      pointer-events: none;
      color: $primary-color;
      >svg{
        position: absolute;
        top:58%;
        left:50%;
        transform: scale(1.6) translate(-50%,-50%);
      }
    }

    >div{
      width: 100%;
    }
  }

  .day{
    color : black;

    &.selected{
      background-color: $primary-color;
      color : white;
    }
  }

  input {
    @include input;
    border-radius: $radius-md;
    min-height: 50px;
    border: 1px solid rgba($dark, 0.2);
    background-color: $bg-color;
    @include label;
    color: $black;
    min-width: 220px;
    &::placeholder {
      font-size: 14px;
      color : #8c8c8c;
    }
  }

}
