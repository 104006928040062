@import '../../styles/_variables.scss';

.container {
  display: flex;
  margin-bottom: 20px;
  &.is-left {
    flex-direction: row-reverse;
    .profile {
      display: flex;
      flex-direction: row-reverse;
      .name {
        margin-left: 5px;
      }
    }
    .info {
      .message {
        text-align: right;
      }
    }
  }
  .icon {
    width: 30px;
    height: 30px;
    background-color: $dark;
    color: $white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    @include medium;
    &.owner {
      background-color: $primary-color;
    }
  }
  .info {
    margin-top: 5px;
    margin: 5px 10px;
    width: 80%;
    p {
      margin-bottom: 4px;
    }
    .name {
      @include medium;
      color: $dark;
    }
    .message {
      margin-top: 10px;
      text-align: left;
      color: $dark;
      font-size: 14px;
    }
  }
}