@import './mixins.scss';
@import './fonts.scss';

$bg-color:  #FFFFFF;
$white: #FFFFFF;
$black: #181844;
$dark: #1C376B;
$dark-grey:#1F232E;

$dark-lighter: #5F657C;
$primary-color: #33B0C5;
$primary-dark: rgb(235, 152, 100);
$primary-extra-light: rgba(118, 230, 232, 0.235);
$puce-color: $primary-color;


$grey:#D8D8D8;
$light-grey: #E3E3E3;
$orange: #FF8469;

$text-color: #84899A;
$text-color-light: #E3E3E3;
$success: #4DCD98;
$danger : red;

$draft: rgba($dark, 0.2);
$new:rgba($success, 0.3);
$accepted: $success;
$pending: #FFC165;

$shadow: 0px 2px 21px 2px rgba(24, 24, 68, 0.0669799);

$lg-screen: 1400px;
$md-screen: 1200px;
$sm-screen: 1024px;
$xs-screen: 576px;

$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$small : 13px;
$regular : 16px;
$h2 : 18px;
$h1 : 25px;

$radius: 3.5px;
$radius-md: 9px;

$border-color: #2B303F;
$border: 1px solid $border-color;

@font-face {
	font-family: "Montserrat-Bold";
	src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Regular";
	src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}




