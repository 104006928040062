@import "../../../styles/variables";

.container {
  height: 100%;
  width: 100%;
  header {
    padding: 20px 70px;
    display: flex;
    align-items: center;
    h2 {
      margin: 0px 0px 0px 0px;
      @include bold;
      text-transform: uppercase;
    }
    .back {
      @include back-btn;
    }
  }
  .form {
    padding: 10px 70px;
    overflow: auto;
    height: calc(100% - 67px);
    max-width: 100%;
    flex-wrap: wrap;
    @media only screen and (min-width: $md-screen) {
      max-width: 60%;
    }
    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .container-field {
        width: calc(50% - 10px);
        margin-bottom: 10px;
      }
    }
    .structure {
      margin: 10px 0px;
    }
    .message-error {
      color: $danger;
    }
    .email {
      display: flex;
      margin-top: 10px;
      margin-bottom: 20px;
      button {
        margin-left: 0px;
        @include btn;
        display: flex;
        align-items: center;
        @include medium;
        color: rgba($dark, 0.5); 
        .check {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          border-radius: 5px;
          border: 1px solid rgba($dark, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: transparent;
          }
          &.active {
            background-color: $primary-color;
            svg {
              color: $white;
            }
          }
        }
      }
    }
    .btn-container {
      margin-top: 20px;
      .btn {
        @include btn;
        &.danger {
          margin-left: 20px;
        }
      }
    }
  }
}