@import "../../styles/variables";

.container {
  height: 100%;
  width: 100%;
  .btn {
    @include btn;
    &.link {
      z-index: 300;
      position: absolute;
      top: 90px;
      right: 70px;
      text-decoration: none;
    }
  }
  .content {
    padding: 10px 50px;
    overflow: auto;
    height: 100%;
    h2 {
      margin: 0px;
      @include bold;
      text-transform: uppercase;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      .events {
        width: 55%;
        .btn {
          @include btn;
          &.primary {
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 5px;
            &.outlined {
              border: 1px solid rgba($black, 0.2);
              color: rgba($black, 0.2);
            }
          }
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
      .filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        .filter {
          display: flex;
          margin-top: 10px;
          margin-left: 10px;
          flex-direction: column;
          @media only screen and (min-width: 1500px) {
            flex-direction: row;
            align-items: center;
            margin-top: 0px;
          }
          p {
            @include bold;
            color: $dark;
            margin: 5px 10px;
          }
        }
      }
    }
    .list {
      margin: 20px 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .grid {
      margin: 20px 0px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 300px;
      grid-gap: 30px;
      @media only screen and (min-width: $lg-screen) {
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 30px;
      }
    }
    .grid div:nth-child(1) {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
  }
}