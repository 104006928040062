@import '../../styles/_variables.scss';

.container {
  @include transition;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $white;
  box-shadow: 0px 2px 21px 2px rgba(24, 24, 68, 0.1);
  border-radius: 9px;
  text-decoration: none;
  color: $dark;
  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 2px 26px 2px rgba(24, 24, 68, 0.2);
  }
  .status {
    position: absolute;
    padding: 10px;
  }
  .img-default {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e8ec;
    border-top-left-radius: $radius-md;
    border-top-right-radius: $radius-md;
  }
  .img {
    height: 110px;
    background-color: #e6e8ec;
    border-top-left-radius: $radius-md;
    border-top-right-radius: $radius-md;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .infos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 20px 20px 20px;
    .indicator {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 23px;
      right: 10px;
      background-color: $puce-color;
      z-index: 200;
    }
    h3 {
      @include bold;
      font-size: 16px;
      margin: 0px 0px 10px 0px;
    }
    p {
      color: $dark;
      font-size: 12px;
      svg {
        color: $primary-color;
        font-size: 18px;
        margin-right: 10px;
        transform: translateY(4px);
      }
    }
    .structure {
      @include structure;
      font-size: 12px;
      margin: 0px;
    }
    .archived {
      @include btn;
      @include text;
      color: $dark;
      font-size: 12px;
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: flex;
      align-items: center;
      color: $primary-color;
      span {
        opacity: 0;
      }
      svg {
        font-size: 18px;
        margin-left: 5px;
      }
      &:hover {
        span {
          opacity: 1;
        }
      }
    }
  }
}
