@mixin page-container {
  height: 100vh;
  width: 100%;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin input {
  @include transition;
  display: block;
  padding: 6px 0;
  border: none;
  outline: none;
  border-radius: 0px;
  width: 100%;
  caret-color: $white;
  box-sizing: border-box;
  background-color: transparent;
  border-bottom: solid 1px transparent;
  font-size: $regular;
  line-height: 17px;
  color: $white;
  &:focus{
    border-bottom: solid 1px rgba($white , 0.2);
  }
  &::placeholder {
    color : #84899A;
  }
}

@mixin btn {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
  &.secondary {
    font-family: "Montserrat-SemiBold";
    background-color: $dark;
    border: none;
    padding: 14px 24px;
    font-size: 14px;
    color: $white;
    border-radius: $radius-md;
    border: 1px solid $dark;
    cursor: pointer;
  }
  &.success {
    font-family: "Montserrat-SemiBold";
    background-color: $success;
    border: none;
    padding: 14px 24px;
    font-size: 14px;
    color: $white;
    border-radius: $radius-md;
    border: 1px solid  $success;
    cursor: pointer;
  }
  &.danger {
    font-family: "Montserrat-SemiBold";
    background-color: transparent;
    border: none;
    padding: 14px 24px;
    font-size: 14px;
    color: $danger;
    border-radius: $radius-md;
    border: 1px solid  transparent;
    cursor: pointer;
    &:hover {
      background-color: $danger;
      border: 1px solid  $danger;
      color: $white;
    }
  }
  &.primary {
    font-family: "Montserrat-SemiBold";
    background-color: $primary-color;
    border: none;
    padding: 14px 24px;
    font-size: 14px;
    color: $white;
    border-radius: $radius-md;
    border: 1px solid $primary-color;
    cursor: pointer;
    &.outlined {
      border: 1px solid $primary-color;
      background-color: $bg-color;
      color: $primary-color;
      &:hover {
        background-color: rgba($primary-color, 0.2);
        color: $primary-color;
      }
    }
  }
  &.primary-icon {
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border: none;
    font-size: 20px;
    color: $text-color;
    border-radius: $radius;
    cursor: pointer;
    &:hover {
      background-color: rgba($primary-color, 0.2);
      color: $primary-color;
    }
    &.active {
      background-color: rgba($primary-color, 0.2);
      color: $primary-color;
    }
  }
}

@mixin back-btn {
  @include transition;
  border: 2px solid rgba($dark, 0.2);
  display: flex;
  padding: 8px 10px;
  margin: 0px;
  margin-right: 20px;
  text-decoration: none;
  border-radius: $radius-md;
  &:hover {
    border: 2px solid rgba($primary-color, 1);
    background-color: rgba($primary-color, 0.1);
  }
  svg {
    color: $primary-color;
    margin-right: 8px;
  }
  p {
    @include bold;
    margin: 0px;
    color: $dark;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin structure {
  background-color: rgba($dark, 0.1);
  padding: 5px 15px;
  border-radius: 20px;
  margin-right: 6px;
  font-size: 14px;
}


@mixin file {
  width: 100%;
  position: relative;
  .new {
    @include transition();
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 23px;
    background-color: $puce-color;
    top: -6px;
    left: -10px;
    z-index: 200;
    transform: scale(0);
    &.active {
      transform: scale(1);
    }
  }
  .img {
    height: 180px;
    background-color: #e6e8ec;
    border-radius: $radius-md;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    iframe {
      pointer-events: none;
      overflow: hidden;
      border: none;  
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 6px;
    button {
      position: relative;
      margin-left: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      @include btn;
      padding: 4px;
      background-color: $white;
      box-shadow: 0px 2px 10px 2px rgba(24, 24, 68, 0.4);
      &.delete {
        &:hover {
          background-color: rgb(248, 185, 185);
        }
        svg {
          color: $danger;
        }
      }
      &.download {
        &:hover {
          background-color: #cce3e7;
        }
        svg {
          color: $primary-color;
        }
      }
    }
    
  }
 
}