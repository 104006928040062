@import "../../../styles/variables";

.container {
  height: 100%;
  width: 100%;
  .order {
    header {
      padding: 20px 40px;
      @media only screen and (min-width: $md-screen) {
        padding: 20px 70px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        .back {
          @include back-btn;
        }
        h2 {
          margin: 0px;
          @include bold;
          text-transform: uppercase;
          margin-right: 10px;
        }
        .status {
          box-shadow: 0px 2px 21px 2px rgba(24, 24, 68, 0.15);
          border-radius: 10px;
        }
        .container-field {
          min-width:  200px;
          margin-left: 20px;
          border-radius: $radius-md;
          &.status-field {
            min-width:  160px;
          }
          &.draft {
            border: 1px solid $draft;
          }
          &.new {
            border: 1px solid $new;
          }
          &.accepted {
            border: 1px solid $accepted;
          }
          &.pending {
            border: 1px solid $pending;
          }
          &.send {
            border: 1px solid $pending;
          }
          &.delivered {
            border: 1px solid $success;
          }
          &.archived {
            border: 1px solid $draft;
          }
        }
      }
      .right {
        .btn-container {
          .btn {
            @include btn;
            text-decoration: none;
            margin-left: 15px;
            &.outline {
              border: 1px solid $primary-color;
              background-color: $white;
              color: $primary-color;
              &.danger {
                border: 1px solid $danger;
                background-color: $white;
                color: $danger;
              }
            }
          }
        }
      }
    }
    .content {
      padding: 20px 40px;
      height: calc(100vh - 196px);
      overflow-y: auto;
      @include scrollbar;
      @media only screen and (min-width: $md-screen) {
        padding: 20px 70px;
      }
      .infos {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 20px;
        background-color: rgba($dark, 0.05);
        border-radius: $radius-md;
        padding: 20px;
        label {
          @include medium;
          color: rgba($dark, 0.5); 
        }
        @media only screen and (min-width: $lg-screen) {
          grid-template-columns: repeat(5, 1fr);
        }
        p {
          margin: 0px;
          font-size: 14px;
          color: $dark;
          @include medium;
        }
      }
      .row {
        display: flex;
        justify-content: space-between;
        .input {
          width: calc(50% - 10px);
        }
        .col {
          width: calc(45% - 20px);
          &:last-child {
            width: calc(55% - 20px);
          }
          section {
            border-bottom: 1px solid rgba($dark, 0.3);
            margin-bottom: 20px;
            padding-bottom: 25px
          }
          .files {
            margin-bottom: 30px;
            .list {
              margin: 20px 0px;
              display: grid;
              grid-gap: 30px;
              grid-template-columns: repeat(2, 1fr);
              grid-auto-rows: minmax(180px, auto);
              .file {
                @include file;
              }
            }
          }
          .invoice {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $dark;
            padding: 10px;
            border-radius: $radius-md;
            p {
              margin: 0px;
              margin-left: 10px;
            }
            button {
              margin-top: 0px;
            }
            label { 
              color:$white;
            }
          }
          h3 {
            @include bold;
            text-transform: uppercase;
            margin: 0px 0px 10px 0px;
          }
        }
      }
    }
  }
}