@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{
  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 8px;
      min-height: 150px
    }
  }

  .input {
    max-width: 100%;
    font-family: inherit;
    font-size: inherit;
    @include input;
    min-height: 200px
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

}
