@mixin bold {
  font-family: "Montserrat-Bold";
}

@mixin medium {
  font-family: "Montserrat-SemiBold";
}

@mixin regular {
  font-family: "Montserrat-Regular";
}


@mixin logo {
  @include transition;
  @include bold;
  font-size: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: block;
  text-decoration: none;
  color: $white;
}

@mixin h1 {
  @include medium;
  font-size: 42px;
}

@mixin label {
	font-family: "Montserrat-SemiBold";
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

@mixin text {
  @include regular;
  font-size: $regular;
  color: $white;
}