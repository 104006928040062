@import "../../styles/variables";

.container {
  @include transition;
  background-color: $white;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  .indicator {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin: 5px;
    &.draft {
      background-color: $draft;
    }
    &.new {
      background-color: $new;
    }
    &.accepted {
      background-color: $accepted;
    }
    &.pending {
      background-color: $pending;
    }
    &.send {
      background-color: $pending;
    }
    &.delivered {
      background-color: $success;
    }
    &.archived {
      background-color: $draft;
    }
  }
  p {
    color: $dark;
    font-size: 12px;
    padding: 0px 10px;
    margin: 0px;
  }
} 