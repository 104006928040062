@import "../../../styles/variables";

.container {
  height: 100%;
  width: 100%;
  header {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    @media only screen and (min-width: $md-screen) {
      padding: 20px 70px;
    }
    .back {
      @include back-btn;
    }
    h2 {
      margin: 0px;
      @include bold;
      text-transform: uppercase;
    }
    .steps {
      display: flex;
      align-items: center;
      margin-left: 10px;
      p {
        margin: 0px 0px 0px 20px;
        padding-top: 2px;
        @include bold;
        text-transform: uppercase;
        color: rgba($black, 0.3);
        border-bottom: 2px solid transparent;
        &.active {
          color: $black;
          border-bottom: 2px solid $primary-color;
        }
      }
    }
  }
  form {
    width: 400vw;
    height: calc(100vh - 200px);
    display: flex;
    @include transition;
  }
}