@import "../../styles/variables";

.row {
  @include transition;
  display: flex;
  border: 1px solid rgba($dark, 0.3);
  border-radius: 9px;
  text-decoration: none;
  .col {
    width: 20%;
    margin: 6px 10px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    &.role {
      @include bold;
    }
    &.structures {
      width: 60%;
      p {
        @include structure;
      }
    }
    &:not(:first-child) {
      border-left: 1px solid rgba($dark, 0.3);
    }
    &:first-child {
      p {
        @include bold;
        color: $dark;
      }
    }
    p {
      margin: 0px;
      color: $dark;
    }
  }
  &:hover {
    border: 1px solid rgba($primary-color, 1);
    background-color: rgba($primary-color, 0.1);
    p {
      color: $primary-color;
    }
  }
}