@import '../../styles/_variables.scss';

.row {
  @include transition;
  display: flex;
  border: 1px solid rgba($dark, 0.3);
  border-radius: 9px;
  text-decoration: none;
  .col {
    width: 20%;
    margin: 6px 10px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    position: relative;
    .indicator {
      width: 15px;
      height: 15px;
      border-radius: 23px;
      margin-right: 5px;
      background-color: $puce-color;
      z-index: 200;
    }
    &.date {
      border-left: 1px solid rgba($dark, 0.3);
      border-right: 1px solid rgba($dark, 0.3);
      p {
        font-size: 14px;
      }
      svg {
        margin-right: 8px;
      }
    }
    &.days {
      display: flex;
      justify-content: flex-end;
      p {
        font-size: 14px;
        &.is-late {
          color: $danger;
        }
        span {
          @include bold;
        }
      }
    }
    &.structures {
      width: 40%;
      padding: 0px;
      p {
        @include structure;
      }
    }
    &:first-child {
      padding: 0px 0px 0px 4px;
      p {
        @include bold;
        color: $dark;
      }
    }
    &.status {
      border-left: 1px solid transparent;
      justify-content: flex-end;
      margin: 0px;
      .border {
        border: 1px solid rgba($dark, 0.3);
        border-radius: 20px;
      }
      button {
        @include btn;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: $primary-color;
        padding: 8px;
        border-radius: 9px;
        &:hover {
          transform: scale(1.3);
        }
      }
    }
    p {
      margin: 0px;
      color: $dark;
    }
  }
  &:hover {
    border: 1px solid rgba($primary-color, 1);
    background-color: rgba($primary-color, 0.08);
  }
}