@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-select{

  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 8px;
      padding: 0px;
    }
  }
  padding: 0px;
  .input {
    @include input;
    padding: 0px;
    min-width: 120px;

  }
  &.primary {
    @include primary;
    padding: 0px;
    .input {
      padding: 0px;
      min-width: 120px;
    }
  }
  &.login {
    @include login;
  }

}
