@import '../../styles/_variables.scss';

.container {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  max-height: 500px;
  h2 {
    @include medium;
    text-transform: uppercase;
  }
  .form-field {
    width: 100%;
    margin-bottom: 20px;
  }
  .btn {
    @include btn;
  }
}