@import '../../styles/_variables.scss';

.row {
  @include transition;
  display: flex;
  border: 1px solid rgba($dark, 0.3);
  border-radius: 9px;
  text-decoration: none;
  .col {
    width: 20%;
    margin: 6px 10px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    p {
      margin: 5px;
    }
    &.event {
      width: 40%;
      padding: 0px;
      p {
        @include structure;
      }
    }
    &:first-child {
      width: 30%;
      p {
        @include bold;
        color: $dark;
      }
    }
  }
  &:hover {
    border: 1px solid rgba($primary-color, 1);
    background-color: rgba($primary-color, 0.08);
  }
}