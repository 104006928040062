@import "../../../styles/variables";

.container {
  height: 100%;
  width: 100%;
  header {
    padding: 20px 70px;
    h2 {
      margin: 0px 40px 0px 0px;
      @include bold;
      text-transform: uppercase;
    }
    .btn {
      @include btn;
      position: absolute;
      top: 90px;
      right: 70px;
      text-decoration: none;
    }
  }
  .content {
    padding: 10px 50px;
    overflow: auto;
    height: calc(100% - 40px);
    h2 {
      margin: 0px;
      @include bold;
      text-transform: uppercase;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filters {
        width: calc(100% - 350px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .container-field {
          width: 250px;
          &:not(:last-child) {
            min-width: calc((100% - 250px) /4);
          }
          margin-left: 10px;
        }
      }
    }
    .list {
      margin: 20px 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .grid {
      margin: 20px 0px;
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(240px, auto);
      @media only screen and (min-width: $lg-screen) {
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(240px, auto);
      }
    }
  }
}