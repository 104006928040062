@import '../../styles/_variables.scss';

.container {
  background-color: rgba($dark, 0.05);
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 10px;
  position: relative;
  .indicator {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 23px;
    background-color: $puce-color;
    top: -6px;
    left: -10px;
    z-index: 200;
  }
  .title {
    @include btn;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      @include medium;
      color: $dark;
      margin: 0px !important;
      &.label {
        @include regular;
        font-size: 14px;
      }
    }
  }
  .content {
    @include transition;
    padding: 8px;
    .list {
      padding-top: 20px;
    }
  }
  form {
    .btn {
      margin-top: 10px;
      @include btn;
      &.primary {
        padding: 5px 10px;
      }
    }
  }
}