@import './variables.scss';

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  color: $black;
  font-family: "Montserrat-Regular" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

.App {
  width: 100%;
  height: 100vh;
  background-color: $bg-color;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, p, a{
  margin: 0 0 $margin-md 0;
}

p, a {
  font-size: $regular;
  color: #84899A;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  box-shadow: 0 0 0 30px transparent inset !important;
  animation-delay: 0.1s;
  animation-name: autofill;
  animation-fill-mode: both;
}

@keyframes autofill {
  0%,100% {
      color: #666 !important;
      background: transparent;
  }
}