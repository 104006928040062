@import '../../../styles/variables.scss';

.container-custom-date-picker{

  .react-datepicker__triangle {
    display: none;
    // border-bottom-color : #d2d2d2;
  }

  .react-datepicker__navigation{
    border: none;
    height: 20px;
    width: 20px;
    margin-top: 8px;
    &:nth-child(3){
      transform: scaleX(-1);
    }

    &-icon--next {
      display: none;
    }

    &-icon--previous {
      display: none;
    }

    &:before,
    &:after{
      position: absolute;
      z-index: 2;
      content:"";
      top:8px;
      display: block;
      width: 10px;
      height: 2px;
      background-color: $primary-color;
    }

    &:after{
      transform: translateY(3px) rotate(45deg);
    }
    &:before{
      transform: translateY(-3px) rotate(-45deg);
    }
  }

  .react-datepicker__header{
    margin-top: 0px;
    background-color: white;
    border-bottom: 1px solid #d2d2d2;

    .react-datepicker__current-month{
      line-height: 20px;
      color : $primary-color;
    }
  }
  .react-datepicker__day--in-range {
    background-color: $primary-color;
  }
  .react-datepicker__day--selected {
    background-color: $primary-color;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }
  .react-datepicker__day--in-selecting-range {
    background-color: rgba($primary-color ,0.5);
  }
  .react-datepicker__day--disabled {
    opacity: 0.3;
    border-radius: 0.3rem;
  }


}

.react-datepicker-popper {
  transform:  translateY(40px);
}