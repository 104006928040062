@import "../../../styles/variables";

.container {
  height: 100%;
  width: 100%;
  header {
    padding: 20px 70px;
    .title {
      h2 {
        margin: 0px 40px 0px 0px;
        @include bold;
        text-transform: uppercase;
      }
    }
    .btn {
      @include btn;
      position: absolute;
      top: 90px;
      right: 70px;
      text-decoration: none;
    }
  }
  .content {
    padding: 10px 50px;
    overflow: auto;
    height: calc(100% - 40px);
    h2 {
      margin: 0px;
      @include bold;
      text-transform: uppercase;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        &:hover {
          color: $primary-color;
        }
        svg {
          margin-right: 6px;
        }
      }
      .filters {
        display: flex;
        align-items: center;
        p {
          @include bold;
          color: $dark;
          margin: 0px 10px;
        }
      }
    }
    .list {
      margin: 20px 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}